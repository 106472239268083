import { graphql, useStaticQuery } from 'gatsby';
import { Topic } from '../types/Topic';

export const useAllTopics = () => {
  const {
    allTopic
  }: { allTopic: { edges: { node: Topic }[] } } = useStaticQuery(graphql`
    query {
      allTopic(sort: { fields: [name], order: ASC }) {
        edges {
          node {
            name
            relatedTopics
            slug
            title
            description
            type
            id
          }
        }
      }
    }
  `);
  return allTopic.edges.map((e) => e.node);
};
