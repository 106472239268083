import {
  Button,
  DialogActions,
  DialogContent,
  InputAdornment,
  TextField,
  Typography
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { compact, keyBy, sum } from 'lodash';
import React, { useMemo, useState } from 'react';
import {
  AdditionActionsMenuOption,
  AdditionalActionsMenu
} from '../../../components/AdditionalActionsMenu';
import { Menu } from '../../../components/AdminMenu';
import { ButtonWithPromise } from '../../../components/ButtonWithPromise';
import {
  IColumn,
  ID_TO_ROW_KEY,
  ItemSorters,
  RowsRenderer,
  ROW_HEIGHTS
} from '../../../components/GroupableList';
import { LinkExternal } from '../../../components/LinkExternal';
import { PlainLoader } from '../../../components/Loader';
import { ProductHuntBanner } from '../../../components/ProductHuntBanner';
import { onlyForAdmins } from '../../../components/ProtectedAdminPage';
import { SEO } from '../../../components/SEO';
import { useErrorLogger } from '../../../hooks/useErrorLogger';
import Layout, { Wrapper } from '../../../layouts/Layout';
import { publishTopic } from '../../../services/admin-pubsub';
import {
  EMPTY_RANKING_STATS_DOC,
  useMonitoredBlogs,
  useRankingStats
} from '../../../services/admin-rankings';
import {
  combineLoadingValues,
  setDoc,
  useMappedLoadingValue
} from '../../../services/db';
import { useAllTopics } from '../../../services/topic';
import styled from '../../../styled';
import { Doc } from '../../../types/Document';
import { MonitoredBlog, RankingStats } from '../../../types/Ranking';
import { SubmissionTopic } from '../../../types/Submission';
import { Topic } from '../../../types/Topic';

const Body = styled('div')`
  margin: ${(p) => p.theme.spacing(4)}px;
`;

export const CanvasBar = styled('div')((p) => ({
  marginBottom: p.theme.spacing() * 2,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  color: p.theme.palette.text.secondary,
  fontSize: p.theme.custom.fontSize.m
}));

const Section = styled('div')`
  margin-bottom: ${(p) => p.theme.spacing(3)}px;
`;

type D = {
  id: string;
  blog: Doc<MonitoredBlog>;
  stats: Doc<RankingStats>;
};
type ColumnName =
  | 'name'
  | 'url'
  | 'topics'
  | 'prevMentions'
  | 'currMentions'
  | 'actions';
type Column = IColumn<D, ColumnName>;

const EditDialogBody = ({
  d,
  onClose
}: {
  d: Doc<MonitoredBlog>;
  onClose: () => void;
}) => {
  const topics = useAllTopics();
  const [value, onChange] = useState(d.data);
  const [topicText, setTopicText] = useState('');
  const submissionTopics = compact(
    value.topics.map((t) => topics.find((a) => a.id === t))
  );
  return (
    <form
      onSubmit={async (ev) => {
        ev.stopPropagation();
        ev.preventDefault();
        await setDoc({
          ...d,
          data: value
        });
        onClose();
      }}
    >
      <DialogContent>
        <Autocomplete
          multiple
          id="topics"
          options={topics}
          getOptionLabel={(topic) => topic.name}
          inputValue={topicText}
          onInputChange={(_, v) => setTopicText(v)}
          onChange={(_, newValue: Topic[]) => {
            onChange({
              ...value,
              topics: newValue.map((t) => t.id as SubmissionTopic)
            });
          }}
          value={submissionTopics}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Blog's Main Topics (Pick Up to 2)"
              margin="normal"
              error={submissionTopics.length > 2}
              helperText={
                submissionTopics.length > 2
                  ? 'You picked too many topics! Narrow it to two, please.'
                  : ''
              }
              placeholder="Choose the blog's main topics"
            />
          )}
        />
        <TextField
          variant="outlined"
          label="Blog URL"
          value={value.url}
          onChange={(e) => {
            onChange({ ...value, url: e.target.value });
          }}
          margin="normal"
          fullWidth
        />
        <TextField
          variant="outlined"
          label="Name of the Blog"
          value={value.name}
          onChange={(e) => {
            onChange({ ...value, name: e.target.value });
          }}
          helperText="e.g. Blogging for Devs or Jane Doe's Blog"
          placeholder="Jane Doe's Blog"
          margin="normal"
          fullWidth
        />
        <TextField
          type="description"
          variant="outlined"
          value={value.description}
          onChange={(e) => {
            onChange({ ...value, description: e.target.value });
          }}
          label="Description of the blog or website (About the length of a tweet, please)"
          multiline
          rows={3}
          helperText="A short description of what visitors can expect to find on the blog"
          placeholder="https://bloggingfordevs.com/blog/"
          margin="normal"
          fullWidth
        />

        <TextField
          variant="outlined"
          value={value.authorName}
          onChange={(e) => {
            onChange({ ...value, authorName: e.target.value });
          }}
          label="Author Name"
          helperText=""
          placeholder="Jane doe"
          margin="normal"
          fullWidth
        />

        <TextField
          variant="outlined"
          value={value.twitterHandle}
          onChange={(e) => {
            onChange({ ...value, twitterHandle: e.target.value });
          }}
          label="Twitter Handle"
          InputProps={{
            startAdornment: <InputAdornment position="start">@</InputAdornment>
          }}
          helperText=""
          placeholder="bloggingfordevs"
          margin="normal"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button type="submit" variant="contained" color="primary">
          Edit
        </Button>
      </DialogActions>
    </form>
  );
};

const takeScreenshot = (blogId: string) =>
  publishTopic({
    topic: 'screenshot-takeScreenshotForBlog',
    payload: {
      id: blogId
    }
  });

const Actions = ({ d }: { d: D }) => {
  const options = useMemo(() => {
    const os: AdditionActionsMenuOption[] = [
      {
        key: 'edit',
        label: 'Edit...',
        dialog: ({ onClose }) => <EditDialogBody d={d.blog} onClose={onClose} />
      },
      {
        key: 'recalcStats',
        label: 'Recalculate stats',
        onClick: () =>
          publishTopic({
            topic: 'ranking-updateStats',
            payload: {
              id: d.blog.id
            }
          })
      },
      {
        key: 'takeScrenshot',
        label: 'Take screenshot',
        onClick: () => takeScreenshot(d.blog.id)
      },
      {
        key: 'updateAuthorImageUrlOnBlogViaTwitter',
        label: 'Update profile image via twitter',
        onClick: () =>
          publishTopic({
            topic: 'ranking-updateAuthorImageUrlOnBlogViaTwitter',
            payload: {
              id: d.blog.id
            }
          })
      }
    ];
    return os;
  }, [d]);
  return <AdditionalActionsMenu options={options} />;
};

const COLUMNS: Column[] = [
  {
    key: 'name',
    head: () => 'Name',
    cell: (d) => d.blog.data.name,
    sortable: true,
    align: 'left',
    width: 150,
    flexGrow: 2
  },
  {
    key: 'url',
    head: () => 'URL',
    cell: (d) => <LinkExternal href={d.blog.data.url} />,
    sortable: true,
    align: 'left',
    width: 150,
    flexGrow: 2
  },
  {
    key: 'topics',
    head: () => 'Topics',
    cell: (d) => d.blog.data.topics.join(', '),
    sortable: false,
    align: 'left',
    width: 150,
    flexGrow: 2
  },
  {
    key: 'prevMentions',
    head: () => 'Prev',
    cell: (d) =>
      sum(d.stats.data.total.slice(0, d.stats.data.total.length / 2)),
    sortable: true,
    align: 'left',
    width: 100,
    flexGrow: 1
  },
  {
    key: 'currMentions',
    head: () => 'Curr',
    cell: (d) => sum(d.stats.data.total.slice(d.stats.data.total.length / 2)),
    sortable: true,
    align: 'left',
    width: 100,
    flexGrow: 1
  },
  {
    key: 'actions',
    head: () => '',
    cell: (d) => <Actions d={d} />,
    sortable: false,
    align: 'left',
    width: 80,
    flexGrow: 0
  }
];
const SORTERS: ItemSorters<D> = {
  name: {
    key: 'name',
    items: { sort: (d) => d.blog.data.name, dir: 'asc' }
  },
  url: {
    key: 'email',
    items: { sort: (d) => d.blog.data.url, dir: 'asc' }
  },
  currMentions: {
    key: 'currMentions',
    items: {
      sort: (d) => sum(d.stats.data.total.slice(d.stats.data.total.length / 2)),
      dir: 'desc'
    }
  }
};
const DEFAULT_SORTER = SORTERS.currMentions;

const AdminRankingsBlogsPage = () => {
  const [ds, loading, error] = useMappedLoadingValue(
    combineLoadingValues(useMonitoredBlogs(), useRankingStats()),
    ([blogs, stats]): D[] => {
      const statsById = keyBy(stats, (s) => s.id);
      return blogs.map((b) => ({
        id: b.id,
        blog: b,
        stats: statsById[b.id] || EMPTY_RANKING_STATS_DOC(b.id)
      }));
    }
  );
  useErrorLogger(error);

  return (
    <Layout>
      <ProductHuntBanner />
      <SEO
        title="Admin"
        siteUrl="https://bloggingfordevs.com"
        pathname="admin/rankings/blogs"
        noIndex
      />
      <Wrapper>
        <Menu />
        <div
          style={{
            margin: '0 auto',
            textAlign: 'center'
          }}
        >
          <Typography variant="h4" component="h1" gutterBottom>
            Rankings Blogs
          </Typography>
        </div>
      </Wrapper>
      <Body>
        {loading && <PlainLoader height={500} />}
        {ds && (
          <Section>
            <CanvasBar>
              <div></div>
              <div>
                <ButtonWithPromise
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    Promise.all(ds.map((d) => takeScreenshot(d.blog.id)))
                  }
                  pending="Starting..."
                >
                  Take screenshots for all
                </ButtonWithPromise>
              </div>
            </CanvasBar>
            <RowsRenderer
              variant="contained"
              columns={COLUMNS}
              rows={ds}
              rowToKey={ID_TO_ROW_KEY}
              sorter={DEFAULT_SORTER}
              sortDirection={DEFAULT_SORTER.items.dir}
              renderHead={true}
              chunkSize={30}
              rootMargin="400px"
              otherProps={undefined}
              rowHeight={ROW_HEIGHTS.dense}
            />
          </Section>
        )}
      </Body>
    </Layout>
  );
};

export default onlyForAdmins(AdminRankingsBlogsPage);
