import { callFirebaseFunction } from './cf';

export interface IPubSubInstruction {
  topic: string;
  payload?: any;
}

export const publishTopic = (d: IPubSubInstruction) => {
  return callFirebaseFunction('pubsub-publishJson', d);
};
